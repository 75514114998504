<template>
  <div class="columns is-centered">
    <div class="column is-3">
      <form class="form-horizontal">
        <fieldset>
          <!-- Form Name -->
          <legend class="has-text-right">Login</legend>

          <!-- Text input-->
          <div class="field">
            <label class="label" for="textinput-0">Email</label>
            <div class="control">
              <input id="textinput-0" v-model="user.email" name="textinput-0" type="email" placeholder="placeholder"
                class="input" readonly onfocus="this.removeAttribute('readonly');">
              <p class="help">Your email here</p>
            </div>
          </div>

          <!-- Password input-->
          <div class="field">
            <label class="label" for="passwordinput-0">Password</label>
            <div class="control">
              <input id="passwordinput-0" v-model="user.password" name="passwordinput-0" type="password"
                placeholder="placeholder" class="input" readonly onfocus="this.removeAttribute('readonly');">
              <p class="help">Specify your password</p>
            </div>
          </div>
          <div class="field border-top">
            <router-link to="/register" class="button is-small is-pulled-left">
              <i class="fa-solid fa-chevron-left mr-2 has-text-grey"></i>
              Register
            </router-link>
            <button class="button is-small is-pulled-right is-info is-outlined" @click.prevent="login">Login</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import Api from '@/services/Api'
export default {
  data() {
    return {
      user: {
        email: '',
        password: ''
      },

      user_store: storeToRefs(useUserStore())
    }
  },
  created() {
    this.$document.title = "Login"
  },
  methods: {
    login() {
      Api(false).post('user/login', this.user)
        .then(r => {
          localStorage.user = JSON.stringify(r.data.user)
          this.user_store.user = r.data.user
          localStorage.token = r.data.token
          this.user_store.is_loggedin = true
          this.user_store.session_check = 1
          this.$router.push({ path: '/home' })
        })
    }
  }
}
</script>


